import AgencyPreview from '../../../static/img/services/agency-preview.jpg';
import AccountabilityPreview from '../../../static/img/services/accountability-preview.jpg';
import ProjectsPreview from '../../../static/img/services/projects-preview.jpg';

export default [
  {
    link: "/services/contable/sueldos",
    name: "Liquidación de Sueldos",
    titlealignment: 'center',
    titlemiddle: true,
    /*description: [
      "Liquidación de sueldos, asesoramiento y consultoría para particulares, pequeñas empresas y Organizaciones de la Sociedad Civil"
    ],*/
    background: AccountabilityPreview
  },
  {
    link: "/services/contable/sirc",
    name: "Rendición de Cuentas",
    titlealignment: 'center',
    titlemiddle: true,
    /*description: [
      "Rendición de cuentas de Organizaciones de la Sociedad Civil ante diversos organismos del Estado."
    ],*/
    background: AgencyPreview
  },
  {
    /*link: "/services/contable/sirc",*/
    name: "Formulación de Proyectos",
    titlealignment: 'center',
    titlemiddle: true,
    /*description: [
      "Rendición de cuentas de Organizaciones de la Sociedad Civil ante diversos organismos del Estado."
    ],*/
    background: ProjectsPreview
  }
];
